import React from 'react';
import { IonContent,IonCard,IonCardHeader,IonCardTitle,IonCardContent, IonPage, IonButton } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import HeaderContainer from '../components/HeaderContainer';
import { AnyCnameRecord } from 'dns';

const OfflinePage: React.FC<RouteComponentProps> = ({match, history}) => {
  return (
    <IonPage>
      <HeaderContainer here={match.url} title="Offline" showBack={false}/>
      <IonContent className="page-bg">
      <IonCard>
          <IonCardHeader className="ion-text-center">
            <IonCardTitle>No network connection found</IonCardTitle>
          </IonCardHeader>
            <IonCardContent>
                Please find a place with a stable internet connection to continue using the application.
            
            </IonCardContent>
          
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default OfflinePage;
