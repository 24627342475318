import React from 'react';
import { IonContent, IonPage, IonItem, IonLabel, IonList, IonButton ,IonItemDivider, useIonAlert, IonIcon, useIonLoading} from '@ionic/react';
import HeaderContainer from '../components/HeaderContainer';
import { RouteComponentProps } from 'react-router';
import { Client } from './ClientPage';
import { Order, OrderItem } from './OrderPage';
import { card } from 'ionicons/icons';

interface OrderSummaryPageProps extends RouteComponentProps { 
  submitOrder: any,
  order: Order | undefined,
  client: Client | undefined
}

const OrderPage: React.FC<OrderSummaryPageProps> = ({ history, match, submitOrder, client, order }) => {
  const [present] = useIonAlert();
  const [isLoading, loadingComplete] = useIonLoading();

  const onSubmit = async () => {
    console.log("onSubmit");
    isLoading();
    const responce = await submitOrder(order);
    loadingComplete();
    if(responce?.status == 200){
      history.push('/thanks');
    }else{
      let $message = responce.message ? responce.message : 'Error saving, please check inputs and try again';
      present($message, [{ text: 'Ok' }]);
    }
  
  };

  return (
    <IonPage>
      <HeaderContainer here={match.url} title="Confirm Purchase" showBack={true}/>
      <IonContent className="page-bg">      
      {order?.order_items !== undefined && order.order_items.map((orderItem: OrderItem, index:number) => (
         orderItem.qty !== undefined && orderItem.qty > 0 && 
          (
          <IonItem key={index}>
            <IonLabel >{orderItem.qty} x {orderItem.product.name} (${orderItem.product.price})</IonLabel>
            <span slot='end'>${orderItem.product.price * orderItem.qty}</span>
          </IonItem>)
        ))}
        <IonItem>
          <b>Credit Card Fee</b> <span slot='end'>${order?.fee}</span>
        </IonItem> 
        <IonItem>
          <b>Total Cost</b> <span slot='end'>${order?.total}</span>
        </IonItem>
        <IonItemDivider>Special Delivery Instuctions</IonItemDivider>
        <IonItem>
          {order?.notes}
        </IonItem>

        <IonItemDivider>Address</IonItemDivider>
          <IonItem>
            {client?.address}
            <IonButton slot="end" routerLink='/client'>Edit</IonButton>
          </IonItem>

        <IonItemDivider>Delivery Day</IonItemDivider>
        <IonItem>
          {client?.suburb?.del_day}
        </IonItem>

        <IonItem>
          <IonLabel>Payment {client?.card && client.card}</IonLabel>
          <IonButton expand="block" routerLink='/card'>
              Update Card&nbsp;<IonIcon icon={card}></IonIcon>
          </IonButton>
        </IonItem>

        <IonButton expand="block" type="submit" className="ion-margin-top" onClick={()=> onSubmit()} >Submit Order</IonButton>
       
      </IonContent>
    </IonPage>
  );
};

export default OrderPage;
