import React from 'react';
import { IonContent,IonCard,IonCardHeader,IonCardTitle,IonCardContent, IonPage, IonButton } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import HeaderContainer from '../components/HeaderContainer';


interface AgreePageProps extends RouteComponentProps { 
}

const CertPage: React.FC<AgreePageProps> = ({match, history}) => {
  return (
    <IonPage>
      <HeaderContainer here={match.url} title="Certification" showBack={true}/>
      <IonContent className="page-bg">
      <IonCard>
          <IonCardHeader className="ion-text-center">
            <IonCardTitle>What is a Certification</IonCardTitle>
            <IonCardContent>
            <p>The image we require will look similar to the below images:
            </p>
            
            <p>
            The Data Plate may be found in your electrical meter box or mounted on the wall above the cylinders.        </p>
          <img className="img-responsive" src="/assets/images/DataPlate.jpg"/>
          <p>
            Issued by the Gas fitter who completed the installation and given to the home owner or landlord/real estate agency.
            </p>
          <img className="img-responsive" src="/assets/images/PaperCertificate.jpg"/>
          </IonCardContent>
          </IonCardHeader>
          <IonButton expand="block" onClick={() => history.goBack()} className="ion-margin-top">
              OK
        </IonButton>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default CertPage;
