import {
  IonContent,
  IonButton,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonCard,
  IonCardHeader,
  IonCardContent,
  useIonAlert,
  IonLabel,
  IonCardTitle,
  IonCardSubtitle,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonItem,
  IonImg,
} from "@ionic/react";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import HeaderContainer from "../components/HeaderContainer";
import "../theme/variables.css";

import validator from "validator";

/*
Status Codes - https://restfulapi.net/http-status-codes/
1xx: Informational – Communicates transfer protocol-level information.
2xx: Success – Indicates that the client’s request was accepted successfully.
3xx: Redirection – Indicates that the client must take some additional action in order to complete their request.
4xx: Client Error – This category of error status codes points the finger at clients.
5xx: Server Error – The server takes responsibility for these error status codes.

Looking for 200 OK

401 Unauthorized - Not signed in
403 Forbidden - Is singed in but no access to what is requested
404 Not Found
408 Request Timeout

*/

interface LoginPageProps extends RouteComponentProps {
  sendEmail: any;
  sendCode: any;
}
const LoginPage: React.FC<LoginPageProps> = ({
  match,
  sendEmail,
  sendCode,
}) => {
  const [present] = useIonAlert();

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [code, setCode] = useState("");

  const onSendEmail = async () => {
    if (!validator.isEmail(email)) {
      present("Please enter an email", [{ text: "Ok" }]);
      return;
    }

    const responce = await sendEmail(email);

    if (responce) {
      setSent(true);
    } else {
      present("Issue sending email, please try again.", [{ text: "Ok" }]);
    }
  };

  const setCardTitle = () => {
    if (sent == false) {
      return "Enter your email";
    } else {
      return "Please check your email";
    }
  };

  const onSubmit = async () => {
    if (!code) {
      present("Please enter the code", [{ text: "Ok" }]);
      return;
    }

    const responce = await sendCode(code);
    if (!responce) {
      present("Code not valid, please try again.", [{ text: "Ok" }]);
    }
  };

  return (
    <IonPage>
      <HeaderContainer
        here={match.url}
        title="Order Gas Online"
        showBack={false}
      />
      <IonContent fullscreen>
        <IonHeader
          collapse="condense"
          style={{ backgroundColor: "#1450A0" }}
          className="ion-text-center"
        >
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonTitle
                  className="ion-padding-top ion-padding-bottom"
                  style={{
                    fontWeight: "normal",
                    fontSize: "30px",
                    color: "white",
                  }}
                >
                  Welcome
                </IonTitle>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <img
                  style={{ width: 250 }}
                  src="/assets/images/Group_39@2x.png"
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonHeader>
        <IonCard className="login-card">
          <IonCardHeader className="ion-text-center">
            <div className="image-container">
              <IonImg src="/assets/Email_Icon.png" className="centered-image" />
            </div>
            {/* <IonText className="ion-text-center ion-text-lg" color="primary"><b>Please enter your email below:</b></IonText> */}
            <IonCardSubtitle className="ion-padding-top">
              {setCardTitle()}
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <>
              {sent === false ? (
                <>
                  <IonItem className="ion-padding-bottom">
                    <IonInput
                      className="login-input"
                      value={email}
                      type="email"
                      placeholder="user@example.com"
                      onIonInput={(e) => setEmail(e.detail.value!)}
                      clearInput
                    ></IonInput>
                  </IonItem>
                  <IonButton
                    fill="outline"
                    expand="block"
                    style={{ color: "#1450A0", borderStyle: "none" }}
                    onClick={() => onSendEmail()}
                  >
                    <b>Submit</b>
                  </IonButton>
                </>
              ) : (
                <>
                  <h3>Code sent to email: {email}. Enter code below.</h3>
                  <IonInput
                    value={code}
                    type="text"
                    placeholder="Enter Code"
                    onIonInput={(e) => setCode(e.detail.value!)}
                    clearInput
                  ></IonInput>
                  <IonButton
                    fill="outline"
                    className="login-button"
                    expand="block"
                    onClick={() => onSubmit()}
                  >
                    Next
                  </IonButton>
                  <IonButton
                    fill="outline"
                    className="login-button"
                    expand="block"
                    onClick={() => setSent(false)}
                  >
                    Code didn't arrive, try again.
                  </IonButton>
                </>
              )}
            </>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
