import { useState, useEffect } from "react";
import { isPlatform } from "@ionic/react";

import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Storage } from "@capacitor/storage";
import { Capacitor } from "@capacitor/core";

export interface UserPhoto {
    filepath: string;
    webviewPath?: string;
    base64Data?: string;
  }

const PHOTO_STORAGE = "photos";
export function usePhotoGallery() {

    const [photo, setPhoto] = useState<UserPhoto>();

    useEffect(() => {
        const loadSaved = async () => {
            const {value} = await Storage.get({key: PHOTO_STORAGE });
            
            if(value){
              const photoInStorage = value ? JSON.parse(value) as UserPhoto : undefined ;
              
              // If running on the web...
              if (!isPlatform('hybrid') && photoInStorage) {
                
                let webPhoto = photoInStorage;
                const file = await Filesystem.readFile({
                  path: webPhoto.filepath,
                  directory: Directory.Data
                });
                // Web platform only: Load the photo as base64 data
                webPhoto.webviewPath = `data:image/jpeg;base64,${file.data}`;
                setPhoto(webPhoto);
              }else{
                setPhoto(photoInStorage);
              }
              
            }
            
          };
        loadSaved();
      }, []);

      const deletePhoto = async (photo: UserPhoto) => {
        // Delete from storage
        Storage.set({key: PHOTO_STORAGE, value: '' });
      
        // delete photo file from filesystem
        const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
        await Filesystem.deleteFile({
          path: filename,
          directory: Directory.Data
        });
        setPhoto(undefined);
      };


      const savePicture = async (photo: Photo, fileName: string): Promise<UserPhoto> => {
        let base64Data: string;
        // "hybrid" will detect Cordova or Capacitor;
        if (isPlatform('hybrid')) {
          const file = await Filesystem.readFile({
            path: photo.path!
          });
          base64Data = file.data;
        } else {
          base64Data = await base64FromPath(photo.webPath!);
        }
        const savedFile = await Filesystem.writeFile({
          path: fileName,
          data: base64Data,
          directory: Directory.Data
        });
      
        if (isPlatform('hybrid')) {
          // Display the new image by rewriting the 'file://' path to HTTP
          // Details: https://ionicframework.com/docs/building/webview#file-protocol
          return {
            filepath: savedFile.uri,
            webviewPath: Capacitor.convertFileSrc(savedFile.uri),
            base64Data: base64Data
          };
        
        }else {
          // Use webPath to display the new image instead of base64 since it's
          // already loaded into memory
          return {
            filepath: fileName,
            webviewPath: photo.webPath,
            base64Data: base64Data
          };
        }
      };
      
    const takePhoto = async () => {

        try{
            const cameraPhoto = await Camera.getPhoto({
                resultType: CameraResultType.Uri,
                source: CameraSource.Camera,
                quality: 100,
                correctOrientation: true
            });

            const fileName = new Date().getTime() + "." + cameraPhoto.format;
            const savedFileImage = await savePicture(cameraPhoto, fileName);
            setPhoto(savedFileImage);

            Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(savedFileImage) })
            return savedFileImage;
        }catch (e){
            console.log('no photo');
        }

        return false;
    };
  
    return {
        deletePhoto,
        photo,
        takePhoto,
    };
  }

  export async function base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('method did not return a string')
        }
      };
      reader.readAsDataURL(blob);
    });
  }