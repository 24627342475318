import React from 'react';
import { IonContent,IonCard,IonCardHeader,IonCardTitle,IonCardContent, IonPage, IonButton } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import HeaderContainer from '../components/HeaderContainer';


interface AgreePageProps extends RouteComponentProps { 
  clearAgree: any
}

const AgreePage: React.FC<AgreePageProps> = ({match, clearAgree}) => {
  return (
    <IonPage>
      <HeaderContainer here={match.url} title="Welcome" showBack={false}/>
      <IonContent className="page-bg">
      <IonCard>
          <IonCardHeader className="ion-text-justify">
            <IonCardTitle>Terms and Conditions</IonCardTitle>
            <IonCardContent>
            <p>Deed 045 - Deed of Variation to Purchase Price</p>
<div>
<br/>
<p><strong>1. </strong><strong>GENERAL</strong></p>
<p>1.1 NRG agrees to hire gas cylinders '<strong>cylinders</strong>' to the Customer, and the Customer agrees to hire the gas cylinders from NRG in accordance with this Agreement. </p>
<p>1.2 Where the Customer exchanges cylinders, this Agreement shall apply in all respects to the exchanged cylinders and any reference to cylinders includes exchanged cylinders. </p>
<p>1.3 &nbsp;The period of hire of the cylinders shall commence on the date of the first delivery and shall continue for a period of twelve (12) months and thereafter for further periods of twelve (12) months.</p>
<p>1.4 '<strong>NRG'</strong> means North Regional Gas Pty Ltd ACN 091 989 972 together with its related entities, associates, directors, employees, contractors, and agents.</p>
<p>1.5 '<strong>Customer</strong>' means the hirer of the cylinder together with their related entities, associates, directors, employees, contractors, agents, tenants, landlord and occupiers.</p>
<p>1.6 <strong>'Location Site' </strong>means the delivery address for the cylinders, or as marked on any gas compliance certificate, as the context requires.</p>
<p>1.7 <strong>'Fee'</strong> means the monetary amount (in AUD) under this Agreement, or any part of the amount payable, inclusive of any specific line items associated with the gas bottle purchase, fitment and/or delivery. </p>
<br/>
<p><strong>2. </strong><strong>LOCATION SITE</strong></p>
<p>2.1 NRG shall install the cylinders at the site marked as the Location Site.</p>
<p>2.2 The Customer acknowledges that it has the responsibility to obtain all requisite approvals under legislation and laws relating to the placing of the cylinders at the Location Site. </p>
<br/>
<p><strong>3. </strong><strong>OFFER AND ACCEPTANCE</strong></p>
<p>3.1 The supply of any cylinders to the Customers by NRG is always subject to these Terms and Conditions. The Customer will be deemed to have accepted these Terms and Conditions by proceeding with the hire of the cylinders:</p>
<p>(a) over the phone enquiry proceeded with payment; or</p>
<p>(b) through an online order from the website or NRG phone application; or</p>
<p>(c) in store purchase; or</p>
<p>(d) reordering any further cylinders from NRG; or</p>
<p>(e) otherwise accepting receipt of any cylinders from NRG. </p>
<p>3.2 NRG shall charge the Customer in accordance with one of the following actions being taken:</p>
<p>(a) an over the phone enquiry, proceeded by confirmation of payment details and processing of the transaction by the NRG Representative; or</p>
<p>(b) through the receipt on an online order from the website, with the confirmation of payment details issued to the Customer by email upon completion of the transaction; or</p>
<p>(c) through the receipt of an online order from the NRG application on a mobile phone. The Customer will be able to view all historical purchases through the History tab on their application profile on their device. </p>
<p>3.3 Should the supply of any cylinders occur through purchase in alignment with subclause 3.1 above, the Customer must also be aware that the following regulations are applicable:</p>
<p>(a) the Customer must place all orders before 4pm on the day prior to the designated delivery day; and</p>
<p>(b) the Customer must make the existing cylinders accessible for the delivery driver; and</p>
<p>(c) the Customer must ensure that any aggressive, escaping or intrusive dogs are kept away from the delivery driver.</p>
<p>3.4 Where the Customer purchases the cylinders in accordance with subclause 3.1(b) above, the Customer will be asked to confirm acceptance of the regulations specified at subclause 3.3 through a tick box item on the ordering page.</p>
<br/>
<p><strong>4. </strong><strong>SERVICE CHARGE AND OTHER FEES</strong></p>
<p>4.1 The Customer shall pay NRG a service charge for the hire of the cylinders in advance of each 12 month period. </p>
<p>4.2 NRG may, on seven (7) days prior written notice to the Customer, vary the service charge for the next 12 month period. </p>
<p>4.3 Without limiting NRG's other rights under this Agreement or at law, in addition to the sum determined under subclause 3.1, if the Customer fails to comply with subclause 3.1, the Customer must pay an overdue fee for each day or part thereof until:</p>
<p>(a) payment is made; or</p>
<p>(b) the cylinders are made available for collection and NRG has been notified that the cylinders are available for collection</p>
<p>4.4 The service charge and other charges and amounts payable under this Agreement do not include gas. If at the end of the term of this Agreement, or upon termination, for any reason gas remains in the cylinders, NRG shall not be liable to pay the Customer for the gas and the gas shall become property of NRG. </p>
<p>4.5 If any cylinder is lost, damaged or stolen (regardless of any negligence or fault on the part of the Customer), the Customer shall be liable to pay to NRG the total cost of the lost, damaged cylinders. The Customer shall permit NRG to charge any credit card or payment method that NRG has on file for the Customer as soon as the Company is aware of the lost, damaged or stolen cylinders for the then full market value of the cylinders.</p>
<p>4.6 NRG reserve the right to alter the charge for cylinders for applicable market value with seven (7) day's notice. </p>
<br/>
<p><strong>5. </strong><strong>CONDITIONS OF HIRE</strong></p>
<p>5.1 The Customer:</p>
<p>(a) must not modify, or permit any modifications, of the cylinders in any way, including but not limited to, removing a plate or marking put on the cylinders by NRG;</p>
<p>(b) must comply with any applicable law, regulation or order with respect to the use of the cylinders;</p>
<p>(c) must not remove or allow the cylinders to be removed from the Location Site other than as expressly permitted by this Agreement or a NRG director;</p>
<p>&nbsp;must ensure that at the end of the term of this Agreement, or upon termination, the cylinders are returned to NRG in good order and repair (other than fair wear and tear). </p>
<p>5.2 Without limiting any other Clause of this Agreement, the Customer:</p>
<p>(a) must immediately notify NRG of any loss, damage, destruction or theft of the cylinders or accidents involving the cylinders during the term of this Agreement and comply with any directions of NRG in relation to the loss, damage, destruction or theft;</p>
<p>(b) is responsible for any loss, damage (other than fair wear and tear), destruction or theft of any item of cylinders irrespective of the cause (except to the extent that NRG recovers an amount from its insurers in respect of the loss, damage, destruction or theft); and </p>
<p>(c) must pay, on demand by NRG, any costs and expenses or other loss incurred or suffered by NRG in relation to any loss, damage (other than fair wear and tear), destruction or theft, including without limitation, the costs of repairing or replacing the cylinder or any part of it.</p>
<p>5.3 During the term of this Agreement, the Customer shall only have the right to exchange cylinders for other cylinders which are property of NRG. The exchange of cylinders with cylinders that are not property of NRG shall be a breach of this Agreement. </p>
<p>5.4 The Customer acknowledges that the filling of the cylinders other than by NRG shall constitute a breach of this Agreement. </p>
<br/>
<p><strong>6. </strong><strong>RELEASE, INDEMNITY AND LIMITATION OF LIABILITY</strong></p>
<p>6.1 The Customer:</p>
<p>(a) indemnifies and shall keep NRG indemnified against any loss or damage which may arise in respect of the cylinders or from the possession, use or operation of the cylinders by the Customer or any other person and against any loss or damage whatsoever or in respect to injury to persons or property arising out of the possession, use or operation of the cylinders by the Customer or any other person in any manner whatsoever during the term of this Agreement; and</p>
<p>(b) indemnifies and shall keep NRG indemnified in respect of the loss, damage, or destruction of the cylinders during the term of this Agreement.</p>
<p>6.2 The Customer may have the benefit of conditions and warranties and other rights implied by law which cannot be excluded by agreement. Nothing in this Agreement shall be taken to limit in any way the benefit of those conditions, warranties and rights. </p>
<p>6.3 Where NRG is permitted to limit its liability in respect of a breach of such implied conditions, warranties or rights, NRG hereby limits its responsibility to the minimum permissible by law. </p>
<br/>
<p><strong>7. </strong><strong>INSURANCES</strong></p>
<p>7.1 NRG will effect and maintain insurance to cover loss and damage to the cylinders during the term of this Agreement.</p>
<p>7.2 Compliance by NRG or the Customer with Clause 6 or Clause 7 of this Agreement does not and shall not be taken to limit or in any way reduce NRG&rsquo;s rights or the Customer&rsquo;s obligations under any other provision of this Agreement or at law.</p>
<br/>
<p><strong>8. </strong><strong>DEFAULT OF THE CUSTOMER AND ENDING THE AGREEMENT</strong></p>
<p>8.1 If the Customer fails or refuses to comply with any of its obligations under this Agreement then, without limiting any of its other rights, NRG may terminate the Agreement.</p>
<p>8.2 If the Customer: </p>
<p>(a) defaults in the punctual payment of any fees and other amounts payable pursuant to this Agreement;</p>
<p>(b) defaults in the observance or performance of any of the terms and conditions of this Agreement;</p>
<p>(c) does, or causes to be done, any act, matter, or thing which is likely to endanger the safety, condition or safe keeping of the cylinders;</p>
<p>(d) where the customer is a natural person, has an order made for the sequestration of assets of the Customer;</p>
<p>(e) where the Customer is a company, has a receiver or receiver and manager, or liquidator, or an official manager is appointed over it or its affairs;</p>
<p>(f) enters into any composition or arrangement with its creditors; or</p>
<p>(g) conducts any other event which constitutes or evidences on the Customer's part, repudiation or refusal to be bound by this Agreement; then</p>
<p>NRG, without limiting its other rights, is entitled to the immediate return of the cylinders and to terminate the Agreement. </p>
<p>8.3 Where an event in subclauses 8.1 and 8.2 occurs, the Customer authorises NRG to enter the Location Site and remove the cylinders. </p>
<p>8.4 Where an event occurs in relation to lost, stolen or damaged cylinders occurs, subclause 4.6 will apply. </p>
<br/>
<p><strong>9. </strong><strong>RETENTION OF TITLE </strong><strong>AND ASSIGNMENT</strong></p>
<p>9.1 The Customer acknowledges and agrees that:</p>
<p>(a) NRG retains full title to the cylinders notwithstanding the possession and use of the cylinders by the Customer;</p>
<p>(b) the Customer shall not have any right, title or interest in the cylinders other than the right to have the cylinders exchanged by NRG in accordance with this Agreement;</p>
<p>(c) the cylinders shall remain personal property and shall not be deemed real property by reason of being attached to any property at the Location Site;</p>
<p>(d) the Customer shall not transfer, deliver, assign or encumber the cylinders in favour of any person otherwise expressly permitted by NRG. </p>
<br/>
<p><strong>10. </strong><strong>PERSONAL PROPERTY SECURITIES ACT</strong></p>
<p>10.1 The Customer acknowledges and agrees that:</p>
<p>(a) the interest of NRG in the Personal Property may be registered on the PPSA Register;</p>
<p>(b) NRG is entitled to register its interest on the PPSA Register under these Terms and Conditions as a purchase money security interest;</p>
<p>(c) notwithstanding any other provision of this document:</p>
<p>i. it has received value as at the date of first delivery of the Personal Property and has not agreed to postpone the time for attachment of the security interest (as defined in the PPSA) granted to the Customer under this document;</p>
<p>ii. the Personal Property is in Australia at the Commencement Date and the Customer warrants that the Personal Property will remain located in Australia during the Term; and</p>
<p>iii. neither the Customer nor NRG will disclose any information to any interested person unless required to do so under the PPSA.</p>
<br/>
<p><strong>11. </strong><strong>CAMPAIGN STARTING 1st MAY 2024</strong></p>
<p>11.1 Unable to gain access to the property due to locked gates or aggressive dog/s.</p>
<p>11.2 No access to cylinders.</p>
<p>11.3 Public Holidays</p>
<p>11.4 Natural Disaster – cyclone/flood/fire</p>
<p>11.5 Delivery to suburbs as per NRG’s roster/schedule</p>
<p>11.6 Order to be placed by COB of previous day before scheduled delivery day.</p>
</div>
          </IonCardContent>
          </IonCardHeader>
          <IonButton expand="block" onClick={() => clearAgree()} className="ion-margin-top">
              I Agree
        </IonButton>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default AgreePage;
