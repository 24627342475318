import { IonContent, 
  IonItem, IonLabel, IonPage,IonItemDivider,
  IonCard,IonCardHeader,IonCardTitle,IonCardContent } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import HeaderContainer from '../components/HeaderContainer';
import { Client } from './ClientPage';

import { Order, OrderItem } from './OrderPage';

interface ThanksPageProps extends RouteComponentProps { 
  order: Order | undefined,
  client: Client | undefined
}

const ThanksPage: React.FC<ThanksPageProps> = ({ match, order, client}) => {


  return (
    <IonPage>
      <HeaderContainer here={match.url} title="Thanks" showBack={false}/>
      <IonContent fullscreen>

      {order  && (
      <IonCard>
      <IonCardHeader className="ion-text-center">
        <IonCardTitle>Thanks for your order</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
    {order?.order_items !== undefined && order.order_items.map((orderItem: OrderItem, index:number) => (
         orderItem.qty !== undefined && orderItem.qty > 0 && 
          (
          <IonItem key={index}>
            <IonLabel >{orderItem.qty} x {orderItem.product.name} at ${orderItem.product.price} each </IonLabel>
          </IonItem>)
        ))}
        <IonItem>
          <b>Credit Card Fee: </b> ${order?.fee}
        </IonItem> 
        <IonItem>
          <b>Total Cost: </b> ${order?.total}
        </IonItem>
        <IonItemDivider>Special Delivery Instuctions</IonItemDivider>
        <IonItem>
          {order?.notes}
        </IonItem>

        <IonItemDivider>Delivery Day</IonItemDivider>
        <IonItem>
          {client?.suburb?.del_day}
        </IonItem>
        </IonCardContent>
      </IonCard>)
      }
      </IonContent>
    </IonPage>
  );
};

export default ThanksPage;
