import { IonContent, 
  IonItem, IonLabel,IonHeader, IonPage, 
  IonTitle, IonToolbar,IonItemDivider,
  IonCard,IonCardHeader,IonCardTitle,IonCardContent } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import HeaderContainer from '../components/HeaderContainer';
import { Client } from './ClientPage';
import '../theme/variables.css';

import { Order, OrderItem } from './OrderPage';

interface HistoryPageProps extends RouteComponentProps { 
  orderHistory: Order[] | undefined,
  client: Client | undefined
}

const HistoryPage: React.FC<HistoryPageProps> = ({ history, match, orderHistory,client}) => {

  const checkHistory = () => {
    if (orderHistory?.length == 0){
      return <IonCard>
              <IonCardHeader> No Order History
              </IonCardHeader>
              <IonCardContent>Your orders will show up here when you purchase gas in the 'Order Gas' tab</IonCardContent>
            </IonCard>
    } else {
      return
    }

  }


  return (
    <IonPage>
      <HeaderContainer here={match.url} title="History" showBack={true} />
      <IonContent fullscreen>
      <IonHeader style={{backgroundColor: '#1450A0', height: '10vh'}} className="ion-padding-top ion-padding-bottom">
        
        <IonTitle className="ion-padding-top ion-padding-bottom" style={{fontWeight: 'normal',fontSize: '25px', color: 'white'}}>Previous Orders</IonTitle> 
      
      
    </IonHeader>
        {checkHistory()}
      

      {orderHistory ? orderHistory.map((order, index) => (
      <IonCard key={index}>
      <IonCardHeader className="ion-text-center">
        <IonCardTitle>Order</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          
    {order?.order_items !== undefined && order.order_items.map((orderItem: OrderItem, index:number) => (
         orderItem.qty !== undefined && orderItem.qty > 0 && 
          (
          <IonItem key={index}>
            <IonLabel >{orderItem.qty} x {orderItem.product.name} at ${orderItem.product.price} each </IonLabel>
          </IonItem>)
        ))}
        <IonItem>
          <b>Credit Card Fee: </b> ${order?.fee}
        </IonItem> 
        <IonItem>
          <b>Total Cost: </b> ${order?.total}
        </IonItem>
        <IonItemDivider>Special Delivery Instuctions</IonItemDivider>
        <IonItem>
          {order?.notes}
        </IonItem>

        <IonItemDivider>Delivery Day</IonItemDivider>
        <IonItem>
        {client?.suburb?.del_day}
        </IonItem>
        </IonCardContent>
    </IonCard>))
    :
    <>
    <p>No past orders on this device</p>
    </>
    }
      </IonContent>
    </IonPage>
  );
};

export default HistoryPage;
