import React, { useState } from 'react';
import { IonContent, 
  IonItem, IonLabel,IonHeader, IonPage, 
  IonTitle, IonInput,IonButton,IonSelect,IonSelectOption,
  IonCard,IonCardHeader,IonCardTitle,IonCardContent,useIonAlert } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import HeaderContainer from '../components/HeaderContainer';
import { useForm } from "react-hook-form";
import { stringify } from 'querystring';

interface CardsPageProps extends RouteComponentProps { 
  saveCard: any
}

export interface Card {
  number: string,
  exp_month: number | undefined,
  exp_year: number | undefined,
  cvc: string | undefined
}

interface CardErrors {
  card: any,
  number: any,
  exp_month: any,
  exp_year: any,
  cvc: any
}


interface FormErrorProps { 
  errors: Record<string, string>
}

const FormError: React.FC<FormErrorProps> = ( props) => {
  const  {errors} = props;

  const  items = Object.entries(errors).map(([key, value])  =>  {
      return  (
        <IonLabel color='danger' key={key}> {value}</IonLabel>
      );
  });

  return (<>
      {items}
  </>);
}

const CardsPage: React.FC<CardsPageProps> = ({ history, match, saveCard}) => {
  const [present] = useIonAlert();
  const defaultValues:Card = {
      number: '',
      exp_month: undefined,
      exp_year: undefined,
      cvc: ''
    };

  const [formErrors, setFormErrors] = useState<CardErrors>();

  const year = new Date().getFullYear();

  const { register, handleSubmit, formState: { errors } } = useForm({defaultValues});

  const onSubmit = async (data:Card) => {
    const responce = await saveCard(data);

    console.log(responce)
    if(responce?.status == 200){
      setFormErrors(undefined);
      history.push('/');//go home
    }else{
      let $message = responce.message ? responce.message : 'Error saving, please check inputs and try again';
      present($message, [{ text: 'Ok' }]);
      setFormErrors(responce.errors);
    }
  };

  return (
    <IonPage>
      <HeaderContainer here={match.url} title="Card" showBack={true} />
      <IonContent fullscreen>

      <IonCard>
      <IonCardHeader className="ion-text-center">
        <IonCardTitle>Add Card</IonCardTitle>
        
        </IonCardHeader>
        <IonCardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
        {formErrors?.card && 
        <FormError errors={formErrors.card}/>}
        <IonItem>
            <IonLabel>Number</IonLabel>
            <IonInput 
              defaultValue=""
              placeholder="**** **** **** ****" 
              type="text" 
              {...register("number", { 
                required: true,
                minLength:12,
                maxLength:19
              })}></IonInput>
            {errors.number && <IonLabel color='danger'>Invalid</IonLabel>}
            {formErrors && formErrors.number && <FormError errors={formErrors.number}/>}
          </IonItem>

          <IonItem>
            <IonLabel>cvc</IonLabel>
            <IonInput 
              defaultValue="" 
              type="text" {...register("cvc", { 
                required: true,
                minLength:3,
                maxLength:4
            })}></IonInput>
            {errors.cvc && <IonLabel color='danger'>Invalid</IonLabel>}
            {formErrors?.cvc && <FormError errors={formErrors.cvc}/>}
          </IonItem>

          <IonItem>
            <IonLabel>Expiry Month</IonLabel>
            <IonSelect 
              defaultValue="" 
              placeholder="MM"
              {...register("exp_month", { required: true })} 
              interface="popover"
            >
            {[...Array(12)].map((x, i) =>
                <IonSelectOption key={i} value={i+1}>{i+1}</IonSelectOption>
              )}
            </IonSelect>
            {errors.exp_month && <IonLabel color='danger'>Invalid</IonLabel>}
            {formErrors?.exp_month && <FormError errors={formErrors.exp_month}/>}
          </IonItem>

          <IonItem>
            <IonLabel>Expiry Year</IonLabel>
            <IonSelect 
              defaultValue="" 
              placeholder="YYYY"
              {...register("exp_year", { required: true })} 
              interface="popover"
            >
            {[...Array(10)].map((x, i) =>
                <IonSelectOption key={i} value={year+i}>{year+i}</IonSelectOption>
              )}
            </IonSelect>
            {errors.exp_year && <IonLabel color='danger'>Invalid</IonLabel>}
            {formErrors && formErrors.exp_year && <FormError errors={formErrors.exp_year}/>}
          </IonItem>

        <IonButton expand="block" type="submit" className="ion-margin-top">
          Add
            </IonButton>
        </form>
        </IonCardContent>
    </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default CardsPage;
