import { IonContent, IonPage, IonList, IonItem, IonLabel,IonHeader,IonToolbar,IonTitle,IonButtons,IonBackButton,IonIcon } from '@ionic/react';
import { withRouter } from 'react-router';
import './HeaderContainer.css';
interface ContainerProps { title: string, here: string, showBack: boolean }

const HeaderContainer: React.FC<ContainerProps> = ({title, here,showBack}) => {

  return (
    <IonToolbar>
      <IonTitle slot="start" style={{color: '#ffffff'}}>{title}</IonTitle>
      <img slot="end" src='/assets/Group_6@2x.png' style={{width:65}} className="ion-padding-end"/>
    </IonToolbar>
  );
};

export default HeaderContainer;
