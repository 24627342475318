import React from 'react';
import { IonFab, IonText, IonCard, IonCardTitle, IonCardHeader, IonContent, IonPage, IonGrid, IonItem, IonThumbnail,IonRow,IonCol, IonButton ,IonItemDivider, IonTextarea,useIonAlert, IonCardContent, IonFabButton, IonTabBar, IonIcon, IonLabel, IonTabButton, IonToolbar, IonHeader, IonTitle } from '@ionic/react';
import HeaderContainer from '../components/HeaderContainer';
import { useForm, Controller } from "react-hook-form";
import { RouteComponentProps } from 'react-router';
import { Client } from './ClientPage';
import { type } from 'os';
import { home } from 'ionicons/icons';


interface OrderPageProps extends RouteComponentProps { 
  saveOrder: any,
  order: Order | undefined,
  client: Client | undefined,
  products: Product[]| undefined
}

interface FormErrorProps { 
  errors: Record<string, string>
}

export interface Product { 
  id: number,
  name: string,
  image: string,
  price: number,
  is_sub: boolean,
  qty: number
}

export interface Order {
  id: number | undefined,
  client_id: number | undefined,
  notes: any | undefined,
  order_items: OrderItem[] | undefined,
  total: number,
  fee: number
}

export interface OrderItem {
  product_id: number | undefined,
  product: Product,
  qty: number | undefined
}

const FormError: React.FC<FormErrorProps> = ( props) => {
  const  errors = props.errors;

  const  items = Object.entries(errors).map(([key, value])  =>  {
      return  (
        <span key={key}> {value}</span>
      );
  });

  return (<>
      {items}
  </>);
}

const OrderPage: React.FC<OrderPageProps> = ({ history, match, saveOrder, client, order, products }) => {
  
  const [present] = useIonAlert();
  const defaultOrderItems = products?.map((product: Product, index:number) =>{
    const order_item: OrderItem = {
      product_id: product.id,
      product: product,
      qty: 0
    };
    return order_item;
  })

  const defaultValues:Order = {
    client_id: client ? client.id : undefined,
    notes: '',
    order_items: defaultOrderItems,
    id: undefined,
    total: 0,
    fee:0
  };

  const fieldRef = React.useRef<HTMLIonButtonElement>();

  const { register, handleSubmit,control  , formState: { errors } } = useForm({defaultValues});

  const onSubmit = async (data: Order) => {

    console.log(data);

    const newOrder = {...order, ...data};

    if(saveOrder(newOrder) == true){
      history.push('/ordersummary');
    }else{
      present('Please select a 45 KG or 18 KG bottle', [{ text: 'Ok' }]);
    }
  };

  return (
    <IonPage style={{outerHeight: 'vmax'}}>
      <HeaderContainer here={match.url} title="Order Gas" showBack={false} />
      <IonContent>
      <IonHeader style={{backgroundColor: '#1450A0', height: '10vh'}} className="ion-padding-top ion-padding-bottom">
        
            <IonTitle className="ion-padding-top ion-padding-bottom" style={{fontWeight: 'normal',fontSize: '25px', color: 'white'}}>Place your order</IonTitle> 
          
          
        </IonHeader>
      <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
      
        
      <input type="hidden" {...register("id")} />
      <input type="hidden" {...register("client_id")} />
      
      {defaultValues.order_items !== undefined && 
         defaultValues.order_items.map((order_item: OrderItem, index:number) => (
         order_item.product.is_sub != true && 
          (
          <IonItem key={index}>
          <IonGrid>
          <IonRow>
            <IonCol><input type="hidden" {...register(`order_items.${index}.product_id` as const)} 
                  value={order_item.product.id} />
                  <IonThumbnail className='product-image'>
             <img src={order_item.product.image}  />
             </IonThumbnail>
             </IonCol>
             <IonCol style={{ maxWidth: 240, minWidth: 200 }}>
            <Controller
              control={control}
              name={`order_items.${index}.qty` as const}
              render={({ field: { value, onChange }}) => (
                <IonGrid>
                  <IonRow><IonCol>{order_item.product.name} @ ${order_item.product.price}</IonCol></IonRow> 
                  <IonRow className="ion-align-items-center">
                  <IonCol><IonButton size="small" expand="full" color="blue" style={{backgroundColor: '#22a9e0', borderRadius: "40%", color: "white", height: 35}}  disabled={ value==0 } onClick={() => onChange( value ?  value - 1 : 0 )} ><IonText style={{fontSize: 30, paddingBottom: 4}}>-</IonText></IonButton></IonCol>
                  <IonCol style={{ color: '#1450A0', fontSize:25, textAlign:"center" }}><b>{value}</b></IonCol>
                  <IonCol><IonButton color="blue" size="small" expand="full" style={{backgroundColor: '#22a9e0', borderRadius: "40%", color: "white", height: 35}} onClick={() => onChange( value ? value + 1 : 1 )} ><IonText style={{fontSize: 30}}>+</IonText></IonButton></IonCol>
                  </IonRow>
                </IonGrid>
              )}
            />
            </IonCol>
      </IonRow>
      </IonGrid>
      
      </IonItem>)
        ))}
        
        <div className="ion-padding-top ion-margin-horizontal" style={{alignItems: 'center'}}>
          <IonText><b>Please input any applicable delivery instructions below</b></IonText>
          <IonCard color="light ion-margin-vertical">
            
            <IonCardContent>
          
              <IonTextarea 
              placeholder="Add your Special Delevery instructions if required"
              {...register("notes")}></IonTextarea>
            </IonCardContent>
          
          </IonCard>
          <IonButton expand="block" color="primary" type="submit" className="ion-margin-top ion-padding-horizontal">
            <IonLabel><b>Proceed</b></IonLabel>
            </IonButton>

          

          
          {/* <IonButton fill="outline" color="blue" style={{color: '#22a9e0', width: 200}} size="default" type="submit">
            <IonLabel><b>Proceed</b></IonLabel>
          </IonButton> */}
          </div>
        
        </form>
      </IonContent>
      {/* <IonFab vertical="bottom" horizontal="center">
            <IonFabButton>Next</IonFabButton>
            <button id="submitBtn" form="order-form" type="submit" className="ion-padding-horizontal"></button>
          </IonFab> */}
    </IonPage>
  );
};

export default OrderPage;
